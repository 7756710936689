
import { Component, Vue, Prop } from 'vue-property-decorator'
import { mapGetters } from 'vuex'
import ModalInputForm from './modal-input-form.vue'

@Component({
    components: {
      ModalInputForm
    },
    computed: {
      ...mapGetters({
        fullPrice: 'Cart/fullPrice'
      })
    }
})
export default class CartItems extends Vue {
  showFullPriceFormat!: any

  calculatedItems!: any[]

  addItemInfo = {
    code: '',
    quantity: 0,
    stock: 0
  }

  formProps = {
    quantity: 0
  }

  inputQuantityStatusIsActive = false

  @Prop()
  groupedItems!: any[]

  calcStock = (onHandQty: number, onReservedQty: number): number => (onHandQty - onReservedQty)

  removeCartItem (skuId: string): void {
    this.$emit('removeCartItem', skuId)
  }

  removeManyCartItems (skuId: string): void {
      this.$buefy.dialog.confirm({
        message: 'ต้องการลบรายการสินค้านี้หรือไม่',
        canCancel: true,
        cancelText: 'ยกเลิก',
        confirmText: 'ลบ',
        type: 'is-danger',
        onConfirm: () => {
          this.$emit('removeManyCartItems', skuId)
        }
      })
  }

  addItemToCart (code: string): void {
    this.$emit('addItemToCart', code)
  }

  adjustManyItem (quantity: number): void {
    this.$emit('addManyItemToCart', { code: this.addItemInfo.code, quantity })
    this.addItemInfo = {
      code: '',
      quantity: 0,
      stock: 0
    }
    this.inputQuantityStatusIsActive = false
  }

  openAddManyItemDialog (code: string, stock: any, quantity: number): void {
    this.addItemInfo = {
      code,
      quantity,
      stock: this.calcStock(stock.onHandQty, stock.onReservedQty)
    }
    this.inputQuantityStatusIsActive = true
    this.formProps.quantity = quantity
  }
}

