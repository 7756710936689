
import { Component, Vue, Watch, Prop } from 'vue-property-decorator'
import MemberProvider from '@/resources/member.provider'
import PromotionCodeProvider from '@/resources/promotion-code.provider'
import dayjs from 'dayjs'
import { mapGetters } from 'vuex'

const memberService = new MemberProvider()
const promotionCodeService = new PromotionCodeProvider()

@Component({
  computed: {
    ...mapGetters({
      storeInfo: 'Store/storeInfo',
      net: 'Cart/net',
      storeSetting: 'Store/storeSetting'
    })
  }
})
export default class MemberCard extends Vue {
  public memberData: any = null

  showPriceFormat!: any

  storeSetting!: any

  storeInfo!: any

  campaignUsed = 0

  net!: any

  spending: any = null

  loading = false

  @Prop()
  memberTel!: string

  @Watch('memberTel', { deep: true, immediate: true })
  async onMemberTelChanged (): Promise<void> {
    if (this.memberTel) {
      this.loading = true
      const { data } = await memberService.getMemberByTel(this.memberTel)
      if (data) {
        this.memberData = data
        const { data: campaignUsedData } = await promotionCodeService.getCampaignUsed(data.id)
        this.campaignUsed = campaignUsedData
        const { data: spendingData } = await promotionCodeService.getSpending(data.id)
        this.spending = spendingData
      }
      this.loading = false
    }
  }

  get isHasExpDate (): boolean {
    return ['vip', 'vvip'].includes(this.memberData.type)
  }

  get vvipSpending70Pecent (): number {
    return (this.storeSetting.memberSetting.vvipSpending / 100) * 70
  }

  get sumHistoriesBuyWithCurrentNet (): number {
    return this.memberData.spending.current + this.net
  }

  formatDate = (utcDate: Date): string => {
    if (utcDate) {
      return dayjs(utcDate).utc().locale('th').format('D MMMM YYYY')
    }

    return '-'
  }
}
