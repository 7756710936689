
import { Component, Vue, Prop } from 'vue-property-decorator'

@Component({})
export default class ModalInput extends Vue {
  @Prop() quantity!: number

  @Prop() addItemInfo!: any

  localQuantity: number = this.quantity

  submitForm (): void {
    this.$emit('submit', this.localQuantity)
  }
}
